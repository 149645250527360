exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-longevity-sessions-js": () => import("./../../../src/pages/longevity-sessions.js" /* webpackChunkName: "component---src-pages-longevity-sessions-js" */),
  "component---src-pages-pillars-js": () => import("./../../../src/pages/pillars.js" /* webpackChunkName: "component---src-pages-pillars-js" */),
  "component---src-pages-pillars-pillar-1-js": () => import("./../../../src/pages/pillars/pillar-1.js" /* webpackChunkName: "component---src-pages-pillars-pillar-1-js" */),
  "component---src-pages-pillars-pillar-2-js": () => import("./../../../src/pages/pillars/pillar-2.js" /* webpackChunkName: "component---src-pages-pillars-pillar-2-js" */),
  "component---src-pages-pillars-pillar-3-js": () => import("./../../../src/pages/pillars/pillar-3.js" /* webpackChunkName: "component---src-pages-pillars-pillar-3-js" */),
  "component---src-pages-pillars-pillar-4-js": () => import("./../../../src/pages/pillars/pillar-4.js" /* webpackChunkName: "component---src-pages-pillars-pillar-4-js" */),
  "component---src-pages-pillars-pillar-5-js": () => import("./../../../src/pages/pillars/pillar-5.js" /* webpackChunkName: "component---src-pages-pillars-pillar-5-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-longevity-sessions-js": () => import("./../../../src/templates/longevity-sessions.js" /* webpackChunkName: "component---src-templates-longevity-sessions-js" */)
}

